<template>
  <b-row v-if="!loading">
    <b-col sm="12" md="6" class="mx-auto">
      <b-card class="success-card p-2" v-if="plan">
        <h1 class="mt-50 text-white">
          {{ $t("subscription.success-title", { plan: plan.title }) }}
        </h1>
        <small class="mb-1 text-white" v-show="false">{{ plan.subtitle }}</small>

        <!-- plan benefit -->
        <b-list-group class="list-group-circle text-left">
          <b-list-group-item
            v-for="(data, index) in plan.features"
            :key="index"
          >
            {{ data.title }}
          </b-list-group-item>
        </b-list-group>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          block
          class="mt-2"
          :variant="'primary'"
          :to="{ name: 'verification-create' }"
        >
          {{ $t("subscription.success-redirect-button-text") }}
        </b-button>
      </b-card>
      <b-card class="success-card p-2" v-else>
        <h1 class="mt-50 text-white">
          {{ $t("subscription.payment-still-processing") }}
        </h1>

        <small class="mb-1 text-white">{{ $t("subscription.please-refresh-page") }}</small>
      </b-card>
    </b-col>
  </b-row>
  <spinner v-else />
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import { info } from "@/api/subscriptions.api";
export default {
  components: { Spinner },
  data() {
    return {
      loading: true,
      plan: null,
    };
  },

  methods: {
    async fetchData() {
      this.loading = true;
      const { data, error } = await info();

      if (error) {
        this.plan = null;
      } else {
        this.plan = data;
      }
      this.loading = false;
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style lang="scss">
.success-card {
  box-shadow: 0 1px 25px 5px var(--primary) !important;
  margin: 2rem;
  &:hover {
    box-shadow: 0 1px 30px 10px var(--primary) !important;
  }
}

[dir]
  .dark-layout
  .list-group
  .list-group-item:not([class*="list-group-item-"]),
[dir] .dark-layout .list-group .list-group-item.list-group-item-action {
  background-color: transparent !important;
  color: white !important;
}

.list-group.list-group-circle .list-group-item:after {
  color: white !important;
}

[dir] .list-group .list-group-item:not([class*="list-group-item-"]),
[dir] .list-group .list-group-item.list-group-item-action {
  background-color: transparent !important;
  color: white !important;
}

.b-list-group-item {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .success-card {
    margin: 2rem 0rem;
  }
}
</style>